<template>
   <div class="container-md">
      <div class="section-title">
         <h2>Anställningar och uppdrag</h2>
      </div>

      <div class="row">
         <div class="col-12 col-xl-6">
            <div class="resume-item">
               <h5>Volvo Group Trucks Technology</h5>
               <h6>2024 Oktober - </h6>
               <p><em>Senior cyber security-ingenjör</em></p>
               <ul>
                  <li>Arkitektur och design av cybersäkerhetslösningar.</li>
                  <li><b>Teknik, Metoder & Verktyg:</b> <em>Intrusion Detection System, SOME/IP, CAN, ISO-TP, mTLS, Linux</em></li>
               </ul>
            </div>

            <div class="resume-item">
               <h5>CTEK</h5>
               <h6>2022 Augusti - 2024 September</h6>
               <p><em>Seniorkonsult</em></p>
               <ul>
                  <li>Design och implementation av cyber security i elbilsladdare. Implementering av en komplett
                      säkerhetskedja, med funktioner som secure boot, provisionering, over-the-air-uppdatering.
                      Implementering av hantering av kryptonycklar, validering, dekryptering och uppdatering, med
                      tekniker som Arm TrustZone och OP-TEE.</li>
                  <li>Design och implementation av signeringsserver.</li>
                  <li>Implementation av generella funktioner i elbilsladdare.</li>
                  <li><b>Teknik, Metoder & Verktyg:</b> <em>Linux, C++, Python, Secure Boot, Arm TrustZone, OP-TEE, AES-256-GCM, YubiHSM 2, PKCS #11, RSA, dm-verity, FastAPI, Yocto</em></li>
               </ul>
            </div>

            <div class="resume-item">
               <h5>Meta</h5>
               <h6>2022 Februari - 2022 Augusti</h6>
               <p><em>Seniorkonsult</em></p>
               <ul>
                  <li>Utveckling av funktioner för och underhåll av Chromium-baserad browser för Facebook Portal.</li>
                  <li><b>Teknik, Metoder & Verktyg:</b> <em>Android, Chromium, C++, Java, MacOS</em></li>
               </ul>
            </div>

            <div class="resume-item">
               <h5>Anders Piniesjö AB</h5>
               <h6>2022 - </h6>
               <p><em>Seniorkonsult i eget företag</em></p>
            </div>

            <div class="resume-item">
               <h5>NIRA Dynamics</h5>
               <h6>2019 - 2022</h6>
               <p><em>Systemingenjör</em></p>
               <ul>
                  <li>Design, utveckling och underhåll av en modulär plattform för loggning av signaler i bil.
                     Centrala delar är utvecklade för att kunna köras i inbyggt system, med utveckling i Python och C++.
                     Systemet kan med hög noggrannhet logga data från exempelvis CAN, FlexRay och Automotive Ethernet.
                     Systemets interface är utvecklat med webbteknik så som Vue.js, Bootstrap och CSS.
                     Arbetet inkluderade kontinuerlig kontakt med utvecklingsprojekt såväl som testförare.</li>
                  <li><b>Teknik, Metoder & Verktyg:</b> <em>C++17, STL, Boost, Python 3, asyncio, Vue.js, BootstrapVue, NPM, CSS, JavaScript, TypeScript, Linux, Docker, Git, Jenkins, CMake, refaktorering, CAN, Automotive Ethernet</em></li>
               </ul>
            </div>

            <div class="resume-item">
               <h5>Zenterio</h5>
               <h6>2017 - 2019</h6>
               <p><em>Seniorutvecklare och Tech Lead</em></p>
               <ul>
                  <li>Ansvarig huvudutvecklare för Zenterios statistik och TR-069-komponenter. Fortsättning på det arbete jag gjorde för Zenterio 2015–2016.</li>
                  <li>Vidareutveckling av Zenterios datainsamlingssystem, TR-069-klient och tillhörande testmiljöer.</li>
                  <li>Design av molnbaserad statistiklösning för Zenterio Cloud. Tech Lead för anpassade statistiklösningar åt kunder.</li>
                  <li><b>Teknik, Metoder & Verktyg:</b> <em>C++, STL, Boost, SQLite, Linux, inbyggda system, Python, Git, TR-069, DVB, JavaScript, Coverity</em></li>
               </ul>
            </div>

            <div class="resume-item">
               <h5>SAAB Aeronautics</h5>
               <h6>2016 - 2017</h6>
               <p><em>Seniorutvecklare</em></p>
               <ul>
                  <li>Designansvarig och utvecklare för ett nytt registreringssystem för kommande mjukvarugeneration för Gripen E. Systemet innefattade konfigurerbara dataflöden för signaler och händelser i flygplanet.</li>
                  <li>Omimplementation av modellerad kod till C++.</li>
                  <li><b>Teknik, Metoder & Verktyg:</b> <em>C++, inbyggda system, Git</em></li>
               </ul>
            </div>
         </div>

         <div class="col-12 col-xl-6">
            <div class="resume-item">
               <h5>Zenterio</h5>
               <h6>2015 - 2016</h6>
               <p><em>Utvecklare</em></p>
               <ul>
                  <li>Huvudutvecklare av och ansvarig för Zenterios statistik och TR-069-komponenter. Arbetet inkluderade högfrekvent insamling av stora
                     mängder mätdata från TV-systemets olika delar samt strukturerad och effektiv lagring. Arbetet inkluderade flera olika klienter för
                     kommunikation av statistik till operatörer samt administration och styrning av enheter via TR-069. Huvudsaklig utveckling i C++
                     med STL och Boost samt lagring i SQLite.</li>
                  <li>Medlem av Zenterios arkitektgrupp, med fokus på teamöverskridande arkitekturfrågor.</li>
                  <li><b>Teknik, Metoder & Verktyg:</b> <em>C++, STL, Boost, SQLite, Python, Linux, inbyggda system, Git, refaktorering, Coverity</em></li>
               </ul>
            </div>

            <div class="resume-item">
               <h5>Zalando, Berlin</h5>
               <h6>2014 - 2015</h6>
               <p><em>Utvecklare</em></p>
               <ul>
                  <li>Utveckling av och ansvar för ett Python-baserat deployment-system, för utrullning av hundratals Java-tjänster,
                     till flera samtidiga serverfarmar samt Amazon Web Services (AWS). Systemet inkluderade webbframsida, beroendehantering,
                     databasmigrering, återställning samt lastbalansering.</li>
                  <li>Design och utveckling av Python-baserat system för skapande och underhåll av Continuous Integration-pipelines för Zalandos alla projekt, med kompilering, testkörning och automatisk utrullning till serverfarm.</li>
                  <li>Utveckling av system för en migrering av Zalandos stora Jenkins-baserade CI-system, från interna servrar till AWS. System för elastisk upp och nedskalning av serverinstanser beroende på last</li>
                  <li><b>Teknik, Metoder & Verktyg:</b> <em>Python, Flask, Docker, AWS, Jenkins, PostgreSQL, Java, Maven, Linux</em></li>
               </ul>
            </div>

            <div class="resume-item">
               <h5>Opera Software</h5>
               <h6>2008 – 2014</h6>
               <p><em>Utvecklare</em></p>
               <ul>
                  <li>Utveckling med fokus på Operas webbläsare för inbyggda Linuxbaserade miljöer, så som TV-apparater, set-top-boxar och mediaspelare. Utveckling i huvudsak i C++, men också med inslag av webbteknik.</li>
                  <li>Design och utveckling av Operas HbbTV-tillägg.  Arbetet inkluderade design och utveckling i C++, såväl som utveckling av ett Python-baserat system för automatiserad integrationstestning.</li>
                  <li>Utveckling av ett PostScript-baserat utskriftssystem för Operas webbläsare, inklusive fullt typsnittsstöd.</li>
                  <li>Portning av Opera till Android.</li>
                  <li>Utveckling och support av ett stort antal kundspecifika utökningar och anpassningar.</li>
                  <li><b>Teknik, Metoder & Verktyg:</b> <em>C++, STL, webbteknik, inbyggda system, Linux, Python, Django, Git, Android</em></li>
               </ul>
            </div>

            <div class="resume-item">
               <h5>Cendio</h5>
               <h6>1999 – 2005</h6>
               <p><em>Utvecklare och konsult</em></p>
               <ul>
                  <li>Design och programmering av klient och utskriftssystem för ThinLinc terminalserverlösning i C++.</li>
                  <li>Design och utveckling av serverkluster, system och övervakningsfunktioner för nya Fröken Ur, för Telia.</li>
                  <li>Design och utveckling av system för distribuerad paketering av data och mjukvara för satellitdistribution till Linux-baserade TV-Boxar, för Nokia.</li>
                  <li>Installation och utveckling av system för hög tillgänglighet och övervakning för rikstäckande webbproxys, för Telia.</li>
                  <li>Utveckling av centraliserat skrivarsystem för kraftigt blandad datormiljö, för SMHI.</li>
                  <li>Installation, support och drift av ett stort antal Linux-system.</li>
                  <li><b>Teknik, Metoder & Verktyg:</b> <em>C++, Python, Linux, kluster, hög tillgänglighet, CVS</em></li>
               </ul>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
  name: 'Resume',
}
</script>

<style lang="scss">
@import "../../../styles/piniesjo_variables";

.resume {
   .resume-title {
      font-size: 26px;
      font-weight: 700;
      margin-top: 20px;
      margin-bottom: 20px;
      color: #45505b;
   }

   .resume-item {
      padding: 0 0 20px 20px;
      margin-top: -2px;
      border-left: 2px solid $side-line;
      position: relative;

      h6 {
         font-size: 16px;
         background: #f7f8f9;
         padding: 5px 15px;
         display: inline-block;
         font-weight: 600;
         margin-bottom: 10px;
      }

      ul {
         padding-left: 20px;

         li {
            padding-bottom: 10px;
         }
      }
   }

   .resume-item:last-child {
      padding-bottom: 0;
   }

   .resume-item::before {
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      border-radius: 50px;
      left: -9px;
      top: 0;
      background: #fff;
      border: 3px solid $side-line;
   }
}

</style>
