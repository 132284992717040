<template>
   <div class="container-md">
      <div class="section-title">
         <h2>Kunskaper</h2>
      </div>

      <div class="row">

         <div class="col-md-12 col-xl-6">
            <div class="skill-section">
               <h5>Operativsystem/plattformar</h5>
               <Skill name="Linux" :value="5"></Skill>
               <Skill name="Embedded Linux" :value="5"></Skill>
               <Skill name="Windows" :value="3"></Skill>
               <Skill name="MacOS" :value="3"></Skill>
               <Skill name="Yocto Project" :value="4"></Skill>
               <Skill name="Cyber Security" :value="4"></Skill>
               <Skill name="TrustZone / OP-TEE" :value="4"></Skill>
            </div>

            <div class="skill-section">
               <h5>Programmering</h5>
               <Skill name="C++" :value="5"></Skill>
               <Skill name="C++17 / C++19" :value="5"></Skill>
               <Skill name="Python 3" :value="5"></Skill>
               <Skill name="C" :value="3"></Skill>
               <Skill name="JavaScript" :value="3"></Skill>
               <Skill name="SQL" :value="4"></Skill>
            </div>

            <div class="skill-section">
               <h5>Webbteknik</h5>
               <Skill name="Vue.js" :value="5"></Skill>
               <Skill name="Django" :value="4"></Skill>
               <Skill name="HTML" :value="5"></Skill>
               <Skill name="CSS" :value="4"></Skill>
               <Skill name="Sass" :value="3"></Skill>
            </div>
         </div>

         <div class="col-md-12 col-xl-6">
            <div class="skill-section">
               <h5>Verktyg</h5>
               <Skill name="Boost" :value="5"></Skill>
               <Skill name="Docker" :value="4"></Skill>
               <Skill name="FastAPI" :value="4"></Skill>
               <Skill name="Git" :value="4"></Skill>
               <Skill name="CMake" :value="4"></Skill>
               <Skill name="JetBrains IDE (PyCharm, CLion, WebStorm)" :value="4"></Skill>
               <Skill name="MySQL" :value="4"></Skill>
               <Skill name="SQLite" :value="4"></Skill>
            </div>

            <div class="skill-section">
               <h5>Metoder</h5>
               <Skill name="Agile/Lean" :value="4"></Skill>
               <Skill name="SCRUM" :value="4"></Skill>
               <Skill name="Continuous Development" :value="5"></Skill>
               <Skill name="Continuous Integration" :value="4"></Skill>
            </div>

            <div class="skill-section">
               <h5>Övrig teknik</h5>
               <Skill name="CAN / CAN-FD / ISO-TP" :value="4"></Skill>
            </div>

         </div>
      </div>
      <div class="row" id="referens">
         <div class="col">
            <em>Som referens till poängsättningen ovan så är områden med 5 punkter sådana jag uppnått en mycket hög nivå av expertkunskap i.
                Exempelvis är Linux, C++ och Python områden jag arbetat professionellt med i över 25 år. För områden med lägre
                poängsättning, exempelvis 3 punkter, så har jag en väl tillräcklig nivå av kunskap för att vara en god tillgång
                professionellt.</em>
         </div>
      </div>

   </div>
</template>


<script>
import Skill from '../Skill'

export default {
   name: 'Skills',

   components: {
      Skill
   },
}
</script>


<style scoped lang="scss">
@import "../../../styles/piniesjo_variables";

.skill-section {
   margin-bottom: 1.5em;
}

#referens {
   margin-top: 2em;
}

</style>
